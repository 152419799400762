

























::selection {
  background-color: #ffa534;
  color: #fff;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #11bfe3;
  border-radius: 10px;
}
// .xxx-p {
//   position: fixed;
//   background: #11bfe3;
//   top: 90px;
//   right: 40px;
// }
