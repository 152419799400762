
















































































































































































































.navbar-minimize {
  .get-llink {
    // background-color: #23ccef;
    background-color: transparent !important;
    border: none;
    i {
      color: #23ccef;
    }
  }
}
i:hover {
  color: #23ccef;
}
.main_wrapper_for_user_details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: 767px) and (max-width: 991px) {
    width: 92%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 80%;
  }
  .user_name_and_img_wrapper {
    @media (min-width: 320px) and (max-width: 480px) {
      text-align: center;
      font-size: 13px;
    }
    .router_link_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      transition: 0.3s;
      flex-wrap: wrap;
      @media (min-width: 320px) and (max-width: 480px) {
        justify-content: center;
      }
      .user_image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #ccc;
        padding: 2px;
        background: #fff;
        transition: 0.3s;
      }
      .user_name {
        text-transform: capitalize;
        color: #777;
        transition: 0.3s;
      }
      &:hover {
        .user_name {
          color: #23ccef;
          transform: translateX(4px);
        }
        .user_image {
          transform: rotate(360deg);
        }
      }
    }
  }
}
