




































.numbers {
  @media (min-width: 320px) and (max-width: 400px) {
    text-align: center !important;
  }
  .card-title {
    font-size: 18px;
  }
}
