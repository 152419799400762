
















































































































































































































































































































































































































































@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600&display=swap");
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: url("/static/img/full-screen-image-4.jpg") no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 50px 20px 50px;
  padding: 0 50px 20px 50px;
  &.is_arabic {
    font-family: "Cairo", sans-serif;
  }
  .card-title {
    margin-top: 0 !important;
  }
  .wrap-of-text {
    font-size: 16px;
    color: #909399;
    text-align: center;
    &.is_arabic {
      direction: rtl;
      color: red !important;
      font-family: "Cairo", sans-serif;
    }
  }
  .every-item {
    width: 60%;
    margin: 20px auto;
    @media (min-width: 320px) and (max-width: 567px) {
      width: 100%;
    }
  }
}
::v-deep .el-input__inner {
  padding: 22px 7px !important;
  transition: 0.3s all ease-in-out;
  background-color: #fff !important;
  border: 1px solid #dee0e6;
  &:focus,
  .is-active,
  &:hover {
    background-color: #fff !important;
    border-color: #23ccef;
  }
}
// ? asterisks
::v-deep {
  .el-form-item {
    .el-form-item__label {
      width: 100%;
      text-align: left;
      // &::before {
      //   content: "" !important;
      // }
    }
    &.is_arabic {
      .el-form-item__label {
        text-align: right;
        font-family: "Cairo", sans-serif;
      }
      .el-form-item__content {
        .just_pass_inarabic {
          direction: rtl;
        }
      }
      .togglePassword {
        left: 12px !important;
        right: auto !important;
      }
    }
  }
}
.main-forgetpass {
  width: 60%;
  margin: 15px auto 0;
  @media (min-width: 320px) and (max-width: 567px) {
    width: 100%;
  }
}
.Forgot-password {
  width: 60%;
  margin: 15px auto 0;
  text-align: right;
  @media (min-width: 320px) and (max-width: 567px) {
    width: 100%;
  }
  .txt {
    color: #e63b45 !important;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.new-account {
  color: #909399;
  &.is_arabic {
    direction: rtl;
    font-family: "Cairo", sans-serif;
  }
  .text2 {
    color: #23ccef;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
.txt2 {
  color: #23ccef;
  font-size: 14px;
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  &.is_arabic {
    font-family: "Cairo", sans-serif !important;
  }
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.lgin-btn {
  transition: 0.3s all ease-in-out;
  opacity: 0.7;
  background-color: #23ccef;
  border: none;
  outline: none;
  color: #ffff;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 1;
  }
}
.submit_lci_buttons {
  background: #00563f;
}
.togglePassword {
  z-index: 5;
  right: 12px;
  top: 75%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}
.ring-icon {
  color: #e63b45;
}
// .logos {
//   width: 300px;
//   height: 220px;
// }

.for-backing {
  position: relative;
  .goo-back {
    background-color: #00563f;

    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
}
.btn-forgetpass {
  background-color: #23ccef;
  border-color: #23ccef;
  color: #fff;
}
.drop-language {
  display: flex;
  flex-direction: row-reverse;
}
.drow-down {
  width: 100px !important;
  text-align: center;
  .el-dropdown-menu__item {
    padding: 0;

    span {
      width: 100%;
      display: block;
      transition: 0.3s all ease-in-out;
      &:hover {
        background-color: #23ccef;
        color: #fff;
      }
    }
  }
}
::v-deep .select-default.el-select .el-input:hover input {
  background-color: #888888 !important;
}
// .submit_lci_buttons {
//   background: #00563f;
// }
// .ttete {
//   background-color: #3c3;
//   background-color: #f93;
//   display: inline;
//   background-color: #39c;
//   background-color: #c36;
// }
$width: 25%;
.login_what_patterns {
  .text_patterns_box {
    width: 80%;
    margin: auto;
    // background: aqua;
    text-align: center;
    font-size: 2.7rem;
    font-weight: bold;
    text-transform: uppercase;
    border: 5px solid transparent;
    // border-left-color: #39c;
    // border-right-color: #c36;
    color: #777;
    position: relative;
    @media (min-width: 700px) and (max-width: 991px) {
      width: 100%;
      font-size: 2.3rem;
    }
    @media (min-width: 460px) and (max-width: 700px) {
      width: 100%;
      font-size: 1.5rem;
    }
    @media (min-width: 320px) and (max-width: 460px) {
      width: 100%;
      font-size: 0.9rem;
    }
    // pat_text
    &::before {
      position: absolute;
      content: "";
      left: 0;
      height: 100%;
      width: 5px;
      background-color: #39c;
    }
    &::after {
      position: absolute;
      content: "";
      right: 0;
      height: 100%;
      width: 5px;
      background-color: #c36;
    }

    .pat_one {
      position: absolute;
      top: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }

    .pat_two {
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 5px;
      width: $width;
      background-color: #39c;
    }
    .pat_three {
      position: absolute;
      top: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }
    .pat_four {
      position: absolute;
      bottom: -5px;
      left: $width;
      height: 5px;
      width: $width;
      background-color: #3c3;
    }

    .pat_five {
      position: absolute;
      top: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }
    .pat_six {
      position: absolute;
      bottom: -5px;
      left: $width * 2;
      height: 5px;
      width: $width;
      background-color: #f93;
    }

    .pat_seven {
      position: absolute;
      top: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
    .pat_eight {
      position: absolute;
      bottom: -5px;
      left: $width * 3;
      height: 5px;
      width: $width;
      background-color: #c36;
    }
  }
}
