










































































.just_wrapping {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  // padding: 0.75rem;
  @media (min-width: 320px) and (max-width: 400px) {
    gap: 0;
  }
  .part_a {
  }
  .part_b {
  }
}
::v-deep .table td,
.table th {
  border: none;
}
