
























































































































































































































































































































.Main_header_with {
  &.is_rtl {
    flex-direction: row-reverse;
  }
}
.overview_our_custom_card {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border: 1px solid #00000020;
  border-radius: 5px;
  padding: 15px;
  min-height: 160px;
  align-items: center;
  margin-bottom: 25px;
  .icons {
    .icon {
      font-size: 45px;
      color: #23ccef;
    }
  }
  .titles {
    text-align: center;
    .main {
      font-size: 20px;
      font-weight: 400;
      color: #9a9a9a;
      text-transform: capitalize;
      margin-bottom: 10px;
    }
    .subtitle {
      color: #333333;
      font-weight: 300;
      font-size: 17px;
    }
  }
}
