






































































































































































































































































































































































































































































































































































































































































































































































































































.class_for_side_tooltips {
}
.el-tooltip__popper.is-dark {
  background-color: #00563f !important;
  color: #fff;
  max-width: 300px;

  &::after {
    border-right-color: #00563f;
  }

  // }
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-right-color: #00563f;
}
.just_display_if_not_mobile {
  display: none;
  @media (min-width: 700px) {
    display: block;
  }
}
