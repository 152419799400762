



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































$just_theme_color: #00563f;
// Start Style For Arabic Language
.alert_google {
  z-index: 400;
  &.is_rtl {
    flex-direction: row-reverse;
    // .el-alert__icon.is-big {
    //   margin-right: 20px;
    // }
    .el-alert__closebtn {
      left: 15px;
      right: auto;
    }
  }
}
.add_custom_statement_with_rtl {
  text-align: right;
  .el-input__inner {
    text-align: right;
  }
}
// End Style For Arabic Language
.main_student_container {
  @media print {
    // body {
    // zoom: 75%;
    // }
  }
  .el-collapse-item__header {
    padding: 2.5rem 0;
    @media print {
      // padding: 1.5rem 0;
    }
  }
  .card-user .card-body {
    overflow: hidden;
  }
  .customization_for_collapse {
    @media print {
      // background: #11bfe3;
      margin: 0 !important;
    }
  }
}
//? tooltip style
.el-tooltip__popper.is-dark {
  @media print {
    display: none !important;
  }
}
.add_new_checked_item {
  color: #fff;
  border-radius: 50%;
  margin-left: 10px;
  width: 20px !important;
  height: 20px !important;
  line-height: 22px;
  font-size: 12px;
  text-align: center;
}
.share_profile_with_rtl {
  text-align: right;
  .el-form-item__label {
    width: 100%;
  }
  .el-input__inner {
    text-align: right;
  }
}
.main_youtube_html_render {
  // background: #11bfe3;
  .text-muted {
    text-align: right;
  }
  .card-category {
    text-align: right;
    list-style: none;
    li {
      text-align: right;
      position: relative;
      padding-right: 17px;
      text-align: right;
      &::before {
        position: absolute;
        content: "";
        background-color: #706f6f;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        right: 0;
        top: 7px;
      }
    }
  }
}

.handle_save_button_dialog {
  background-color: $just_theme_color;
}
