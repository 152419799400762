





























































































































































































.textddddd {
  position: relative;
  .shiwwwww {
    position: absolute;
  }
}

.sidebar_style {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}
